import background_image from './Assets/backgroundImg.svg';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { styled, GlobalStyles as GS } from '@mui/system';
import { Button, Tabs, Link as muiLink, ButtonProps, Typography } from '@mui/material';
import { Email } from '@mui/icons-material';
import Text from './Components/Text';
import { PureLightTheme } from './defaultTheme/schemes/PureLightTheme';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

// Define the types for your styled component props
interface StyleProps {
  action?: boolean;
  big?: boolean;
  fontbig?: boolean;
  lightbg?: boolean;
  imgstart?: boolean;
  $start?: boolean;
  lightTopLine?: boolean;
  lightText?: boolean;
  lightTextDesc?: boolean;
  click?: boolean;
  marginBottom?: boolean;
  buttontype?: boolean;
  layout?: 'row-reverse';
  layout2?: 'row' | 'column';
  fullwidth?: boolean;
  buttonIcon?: boolean;
  emailIcon?: boolean;
}

interface Gen3ButtonProps {
  action?: boolean;
  fullwidth?: boolean;
  fontbig?: boolean;
  buttontype?: boolean;
  activeButton?: boolean;
}

const GlobalStyles = (
  <GS
    styles={{
      '*': {
        padding: 0,
        margin: 0,
        boxSizing: 'border-box'
      },
      '*::scrollbar': {
        scrollbarWidth: 'thin',
        scrollbarColor: 'rgba(155, 155, 155, 0.5) transparent'
      },
      '*::-webkit-scrollbar': {
        width: '0.5px'
      },
      '*::-webkit-scrollbar-track': {
        background: 'transparent'
      },
      '*::-webkit-scrollbar-thumb': {
        background: 'rgba(155, 155, 155, 0.5)',
        borderRadius: '20px',
        border: 'transparent'
      },
      body: {
        margin: 0,
        fontFamily: 'Poppins, sans-serif',
        height: '100%',
        width: '100%'
      }
    }}
  />
);

export const FormPageContainer = styled('div')(({ theme }) => ({
  marginTop: '50px',
  paddingTop: '40px',
  width: '90%',
  margin: '0 auto',
  minHeight: '68vh',

  [theme.breakpoints.down('sm')]: {
    padding: '20px',
    marginTop: '30px'
  }
}));

export const TypographyPrimary = styled(Typography)(
  ({ theme }) => `
    color: ${PureLightTheme.colors.alpha.trueWhite[100]};
  `
);

export const TypographySecondary = styled(Typography)(
  ({ theme }) => `
    color: ${PureLightTheme.colors.primary.light};
  `
);

export const WarningTwoToneIconWrapper = styled(WarningTwoToneIcon)(
  ({ theme }) => `
    color: ${PureLightTheme.colors.warning.main};
`
);

export const FormContainer = styled('section')(({ theme }) => ({
  marginTop: '8px', // Added 'px' unit
  marginBottom: '28px', // Added 'px' unit
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: '#f1f1f1',
  border: '2px solid #125b50', // Combined border properties into a single property
  borderRadius: '5px',
  padding: '20px',
  width: '100%',
  boxSizing: 'border-box', // Corrected 'borderBox' to 'border-box'
  [theme.breakpoints.down('sm')]: {
    padding: '20px',
    marginTop: '30px'
  }
}));

export const SpaceAfterTypography = styled('div')({
  marginBottom: '8px'
});

export const Container = styled('div')(({ theme }) => ({
  zIndex: '1',
  width: '100%',
  maxWidth: '1300px',
  marginRight: 'auto',
  marginLeft: 'auto',
  paddingRight: '10px',
  paddingLeft: '10px',
  [theme.breakpoints.down('sm')]: {
    paddingRight: '5px',
    paddingLeft: '5px'
  }
}));

export const SectionContainer = styled('div')(({ theme }) => ({
  zIndex: '1',
  width: '100%',
  maxWidth: '1300px',
  [theme.breakpoints.down('sm')]: {
    paddingRight: '5px',
    paddingLeft: '5px'
  }
}));

export const BannerContainerStyle = styled('div')<StyleProps>`
  && {
    color: #125b50;
    background: ${({ lightbg }) => (lightbg ? '#125B50' : '#101522')};
  }
`;

export const BannerCover = styled('div')({
  backgroundImage: `url(${background_image})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'auto'
});

export const BannerText1 = styled('div')({
  margin: '85px 65px 5px'
});

export const BannerText2 = styled('div')({
  margin: '15px 65px 15px;'
});

export const BannerImg = styled('img')({
  maxWidth: '100%',
  backgroundImage: `url(${background_image})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'auto'
});

export const Gen3Buttons = styled(
  ({
    action,
    fullwidth,
    fontbig,
    buttontype,
    ...props
  }: Gen3ButtonProps & Omit<ButtonProps, keyof Gen3ButtonProps>) => <Button {...props} />
)(({ action, fullwidth, fontbig, buttontype, theme }) => ({
  borderRadius: '25px', // More rounded corners
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: action ? '2px solid #FF7D13' : '2px solid #125B50',
  backgroundColor: action ? '#FF7D13' : '#125B50',
  color: '#FFFFFF',
  padding: fullwidth ? '12px 24px' : '10px 20px',
  fontSize: fontbig ? '20px' : '16px',
  fontWeight: buttontype ? 'normal' : 'bold',
  cursor: 'pointer',
  textAlign: 'center',
  width: fullwidth ? '100%' : 'auto',
  fontFamily: 'Poppins, sans-serif',
  transition: 'all 0.3s ease-out',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  textTransform: 'uppercase',
  letterSpacing: '1px',
  position: 'relative',
  overflow: 'hidden',
  '&:hover': {
    backgroundColor: action ? '#E66C00' : '#0E4A40',
    borderColor: action ? '#E66C00' : '#0E4A40',
    boxShadow: '0 6px 8px rgba(0, 0, 0, 0.15)',
    transform: 'translateY(-2px)'
  },
  '&:active': {
    transform: 'translateY(1px)',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 0,
    height: 0,
    background: 'rgba(255, 255, 255, 0.2)',
    borderRadius: '50%',
    transform: 'translate(-50%, -50%)',
    transition: 'width 0.3s ease-out, height 0.3s ease-out'
  },
  '&:hover::after': {
    width: '300px',
    height: '300px'
  }
}));

export const StyledIconButton = ({ action, fullwidth, fontbig, children, onClick }) => {
  return (
    <button
      style={{
        borderRadius: '10px',
        display: 'inline-flex',
        flexDirection: 'row',
        borderColor: action ? '#FF7D13' : '#125B50',
        background: action ? '#FF7D13' : '#125B50',
        whiteSpace: 'nowrap',
        padding: fullwidth ? '10px 25%' : '5px 30px',
        color: action ? '#000' : '#fff',
        fontSize: fontbig ? '20px' : '16px',
        fontWeight: 'bold',
        cursor: 'pointer',
        textAlign: 'center',
        width: fullwidth ? '100%' : '300px',
        fontFamily: 'Poppins, sans-serif'
      }}
      onClick={onClick}
    >
      <Email
        style={{
          fill: action ? '#000' : '#fff', // Adjust the icon color based on your button style
          display: 'inherit',
          marginRight: '8px', // Adjust the spacing between icon and text
          marginLeft: '-4px'
        }}
      />
      {children}
    </button>
  );
};

export const DashboardActionButtons = styled(Button)<StyleProps>`
  && {
    border: 1px solid #125b50;
    color: #125b50;
    paddng: 8px 10px;
    &:hover {
      color: #000; /* Text color on hover */
      background-color: ligthen(#125b50, 0.3);
    }
  }
`;

export const ButtonLinks = styled(muiLink)<StyleProps>`
  && {
    text-decoration: none;
    &:hover {
      color: #000;
      background-color: ligthen(#125b50, 0.3);
      text-decoration: none;
    }
  }
`;

export const PopOverAvatar = styled('div')({
  cursor: 'pointer',
  padding: '0px 15px',
  fontWeight: 'bold',
  zIndex: '997',
  backgroundColor: 'white'
});

export const SignupForm = styled('div')({
  backgroundColor: '#FF7D13'
});

export const InfoSec = styled('div')<StyleProps>`
  && {
    color: #fff;
    padding: 160px 0;
    background: ${({ lightbg }) => (lightbg ? '#125B50' : '#101522')};
  }
`;

export const InfoRow = styled('div')<StyleProps>`
  && {
    display: flex;
    margin-right: 10px;
    margin-left: 20px;
    padding-left: 50px;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: ${({ imgstart }) => (imgstart ? 'row-reverse' : 'row')};

    @media screen and (max-width: 960px) {
      margin: 0px;
      padding: 0px;
    }
  }
`;

export const BannerRow = styled('div')<StyleProps>`
  && {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: ${({ imgstart }) => (imgstart ? 'row-reverse' : 'row')};
  }
`;

export const BannerCol2 = styled('div')`
  && {
    flex: 1;
    max-width: 50%;
    flex-basis: 50%;

    @media (min-width: 480px) and (max-width: 1200px) {
      max-width: 100%;
      flex-basis: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    @media (min-width: 320px) and (max-width: 480px) {
      max-width: 100%;
      flex-basis: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;

export const StyledCard = styled('div')<StyleProps>(({ theme, layout2 }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#fff',
  borderRadius: '15px',
  margin: '40px 0',
  padding: '60px',
  maxWidth: '345px', // Added 'px' unit
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.15)', // Fixed property name 'boxshadow' to 'boxShadow'

  flexDirection: layout2 || 'row',

  '& img': {
    width: '80%'
  },

  '& > div': {
    flex: 1
  },

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column'
  }
}));

export const SectionRectangle = styled('div')<StyleProps>`
  && {
    color: #fff;
    height: 80px;
    width: 135%;
    background: ${({ lightbg }) => (lightbg ? '#125B50' : '#101522')};
  }
`;

export const AboutUsRow = styled('div')<StyleProps>`
  && {
    display: flex;
    margin: 10px -15px -15px -15px;
    flex-wrap: wrap;
    align-items: left;
    flex-direction: ${({ imgstart }) => (imgstart ? 'row-reverse' : 'row')};

    @media screen and (max-width: 960px) {
      margin: 0px;
    }
  }
`;

export const AboutUsColumn = styled('div')`
  && {
    flex: 1;
    max-width: 50%;
    flex-basis: 50%;

    @media (min-width: 480px) and (max-width: 1200px) {
      max-width: 100%;
      flex-basis: 100%;
      display: flex;
      justify-content: center;
    }

    @media (min-width: 320px) and (max-width: 480px) {
      max-width: 100%;
      flex-basis: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;

export const AboutUsTitle = styled('h2')(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 700,
  color: '#125B50', // Your primary color
  textAlign: 'center',
  marginBottom: '1.5rem',
  position: 'relative',
  padding: '0.5rem 0',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '80px',
    height: '4px', // Your secondary/accent color
    borderRadius: '2px'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem'
  }
}));

export const SectionTitle = styled('h3')(({ theme }) => ({
  fontSize: '2.0rem',
  fontWeight: 650,
  color: '#125B50', // Your primary color
  textAlign: 'center',
  marginBottom: '1.5rem',
  position: 'relative',
  padding: '0.5rem 0',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '80px',
    height: '4px', // Your secondary/accent color
    borderRadius: '2px'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem'
  }
}));

export const AboutUsText = styled('div')`
  && {
    font-family: 'Poppins, sans-serif';
    font-style: normal;
    font-size: 28px;
    line-height: 40px;
    color: #000000;
    flex: 1;
    padding: 20px;
    margin: 0px;

    @media screen and (max-width: 960px) {
      margin: 0px;
    }
  }
`;

export const SectionHeaderWrapper = styled('div')`
  && {
    padding-top: 0;
  }
`;

export const InfoColumn = styled('div')`
  && {
    margin-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 1;
    max-width: 50%;
    flex-basis: 50%;

    @media (min-width: 480px) and (max-width: 1200px) {
      max-width: 100%;
      flex-basis: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    @media (min-width: 320px) and (max-width: 480px) {
      max-width: 100%;
      flex-basis: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;

export const ImgWrapper = styled('div')<StyleProps>`
  && {
    max-width: 555px;
    display: flex;
    justify-content: ${({ $start }) => ($start ? 'flex-start' : 'flex-end')};
  }
`;

export const Img = styled('img')`
  && {
    padding-right: 0;
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
    max-height: 500px;
  }
`;

export const HomeMetrics = styled('div')`
  && {
    background: #25bd4e;
    display: flex;
    justify-content: space-around;

    @media screen and (max-width: 991px) {
      flex-direction: column;
    }
  }
`;

export const MetricsSubTitle = styled('div')<StyleProps>`
  && {
    font-size: 20px;
    font-weight: 500;
    color: #ffe856;
  }
`;

export const CountContent = styled('div')<StyleProps>`
  && {
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 25px 50px;
  }
`;

export const CountNumber = styled('div')<StyleProps>`
  && {
    font-size: 25px;
    font-weight: 600;
    color: #ffe856;
  }
`;

export const CountLabel = styled('div')<StyleProps>`
  && {
    font-size: 30px;
    font-weight: 600;
    color: #ffe856;
  }
`;

export const Nav = styled('nav')`
  && {
    background: #125b50;
    height: 80px;
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    font-size: 1.2rem;

    @media screen and (max-width: 1280px) {
      padding: 0.5rem 0.5rem;
      font-size: 1rem;
    }
  }
`;

export const NavbarContainer = styled('div')`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 1280px) {
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 1rem;
      height: auto;
    }
  }
`;

export const NavLogo = styled(Link)`
  && {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;

    @media screen and (max-width: 1280px) {
      margin-bottom: 1rem;
    }
  }
`;

export const NavIcon = styled('img')`
  && {
    height: 5rem;

    @media screen and (max-width: 1280px) {
      height: 3rem;
      margin-right: 0.5rem;
    }
  }
`;

export const NavMenu = styled('ul')<StyleProps>`
  && {
    display: flex;
    align-items: center;
    list-style: none; /* Corrected property name */
    text-align: center;
    flex-flow: row nowrap;
    color: white;

    @media screen and (max-width: 1280px) {
      flex-direction: column;
      width: 100%;
      padding: 0;
      margin: 0;
    }
  }
`;

export const NavItem = styled('li')<StyleProps>`
  && {
    height: 80px;
    border-bottom: 2px solid transparent;
    border-radius: 2px;
    padding-top: 25px;
    font-size: 1.5rem;
    font-family: 'Poppins, sans-serif';

    &:hover {
      border-bottom: 4px solid #fff;
    }

    @media screen and (max-width: 1280px) {
      width: 100%;
      height: auto;
      text-align: center;
      padding: 0.5rem 0;
      font-size: 1.2rem; /* Corrected property name */

      &:hover {
        border-bottom: none;
      }
    }
  }
`;

export const NavLinks = styled(HashLink)`
  color: #ffffff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  flexshrink: 0;

  &:hover {
    color: #fde74c;
    transition: all 0.3s ease;
  }

  @media screen and (max-width: 1280px) {
    display: block;
    padding: 0.5rem 0;
  }
`;

export const NavItemBtn = styled('li')`
  && {
    @media screen and (max-width: 1280px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }
`;

export const NavButton = styled(Button)<Gen3ButtonProps>`
  border-radius: 20px; // Removed single quotes
  padding: 8px 16px; // Removed single quotes
  margin: 0 4px; // Removed single quotes
  font-size: 14px; // Removed single quotes
  font-weight: bold; // Removed single quotes
  text-transform: uppercase; // Removed single quotes
  letter-spacing: 0.5px; // Removed single quotes
  color: activeButton ? '#FFFFFF' : '#125B50';
  background-color: activeButton ? '#FF7D13' : '#125B50';
  border: 2px solid #125B50;
  transition: all 0.2s ease-out;
  &:hover: {
    background-color: activeButton ? '#E66C00' : '#0E4A40',
    color: '#FFFFFF',
    box-shadow: '0 6px 8px rgba(0, 0, 0, 0.15)',
    transform: 'translateY(-2px)'
  },
  &:active: {
    transform: 'translateY(1px)',
    box-shadow: 'none'
  },
  &::after: {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 0,
    height: 0,
    background: 'rgba(255, 255, 255, 0.3)',
    border-radius: '50%',
    transform: 'translate(-50%, -50%)',
    transition: 'width 0.2s ease-out, height 0.2s ease-out'
  }
`;

export const NavBtnLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;

  @media screen and (max-width: 1280px) {
    display: flex;
    flex-direction: column;
  }
`;

export const HamburgerIcon = styled('div')`
  && {
    display: none;

    @media screen and (max-width: 960px) {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  }
`;

export const LandingContent = styled('div')`
  && {
    @media (max-width: 1200px) {
      padding: 0.25rem 0.25rem;
    }
  }
`;

export const ProgramContainer = styled('div')`
  && {
    margin-top: 50px 0px;
    padding-top: 30px;

    @media screen and (max-width: 1200px) {
      padding: 20px;
      margin-top: 30px;
    }
  }
`;

export const ProgramWrapper = styled('div')`
  && {
    background: #25bd4e;
    padding: 25px 26%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins, sans-serif';
    font-style: normal;
    font-weight: 400;
    font-size: 39px;
    line-height: 50px;
    text-align: center;
  }
`;

export const CardContainer = styled('div')`
  && {
    flex: 1;
    max-width: 100%;
    flex-basis: 100%;

    @media (min-width: 480px) and (max-width: 1200px) {
      display: flex;
      justify-content: center;
    }

    @media (min-width: 320px) and (max-width: 480px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;

export const ProgramTitle = styled('span')`
  && {
    margin: 15px 0;
    font-size: 25px;
    font-weight: 600;
    font-family: 'Poppins, sans-serif';
    color: #ffffff;
    display: flex;
    align-items: center;
  }
`;

export const ProgramCard = styled('div')`
  && {
    padding-left: 120px;
    padding-right: 120px;
    padding-top: 30px;
    margin-bottom: 30px;

    @media screen and (max-width: 1200px) {
      padding: 0px;
      margin-top: 30px;
      margin-bottom: 10px;
    }
  }
`;

export const AnalyticsTilesContainer = styled('div')`
  && {
    background: #d1f6ff;
    width: 98%;
    height: 162px;
    border-radius: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media screen and (max-width: 475px) {
      flex-direction: column;
      margin-top: 10px;
      padding: 10px;
      height: 100%;
    }
  }
`;

export const AnalyticsTilesWrapper = styled('div')`
  && {
    display: grid;
    justify-items: center;
  }
`;

export const AnalyticsTilesTitle = styled('div')`
  && {
    height: 27.35px;
    font-family: 'Poppins, sans-serif';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    margin: 0 0px 30px 0;
    text-align: center;
    letter-spacing: 0.03em;
    color: #000000;

    @media screen and (max-width: 475px) {
      font-size: 20px;
      height: 20px;
    }
  }
`;

export const NoDatafound = styled('div')`
  && {
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    margin: 15px 0;
  }
`;

export const AnalyticsTilesCount = styled('div')`
  && {
    height: 27.35px;
    font-family: 'Poppins, sans-serif';
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    margin: 0 0px 27px 0;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #000000;
    @media screen and (max-width: 475px) {
      font-size: 25px;
      height: 10px;
    }
  }
`;

export const VerticalLine = styled('div')`
  && {
    width: 1px;
    background-color: rgb(87, 86, 86);
    height: 70%;
    float: left;

    @media screen and (max-width: 475px) {
      width: 0px;
    }
  }
`;

export const HourTilesContainer = styled('div')`
  && {
    height: 162px;
    background: #ddfdf7;
    border-radius: 9px;
    display: grid;
    justify-content: center;
    align-items: center;
    justify-items: center;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;

    @media screen and (max-width: 475px) {
      display: flex;
      flex-direction: column;
      padding: 5px;
    }
  }
`;

export const TilesHeader = styled('div')`
  && {
    font-family: 'Poppins, sans-serif';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.03em;
    color: #000000;
    padding: 25px 0 0 0;

    @media screen and (max-width: 475px) {
      padding: 0px;
      margin: 0px;
    }
  }
`;

export const HourMTitle = styled('span')`
  && {
    font-family: 'Poppins, sans-serif';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.03em;
    color: #000000;

    @media screen and (max-width: 475px) {
      padding: 0px;
      margin: 0px;
    }
  }
`;

export const TimeDivider = styled('span')`
  && {
    font-family: 'Poppins, sans-serif';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 28px;
    letter-spacing: 0.03em;
    color: #000000;
    margin: 0 3px;

    @media screen and (max-width: 475px) {
      padding: 0px;
      margin: 0px;
    }
  }
`;

export const MinutesStyle = styled('span')`
  && {
    font-family: 'Poppins, sans-serif';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 28px;
    letter-spacing: 0.03em;
    color: #000000;
    margin: 0px 5px;

    @media screen and (max-width: 475px) {
      font-size: 20px;
      padding: 5px;
      margin: 0px;
    }
  }
`;

export const LoggedTimeDetails = styled('span')`
  && {
    padding: 0 0 40px 0;

    @media screen and (max-width: 475px) {
      padding: 0px;
      margin: 0px;
    }
  }
`;

export const ClassMetricsText = styled(Text)`
  && {
    font-style: bold;
    color: #125b50;
    font-family: 'Poppins, sans-serif';
    font-size: 20px;
    font-weight: bold;
  }
`;

export const ClassMetricsTitle = styled(Text)({
  fontFamily: 'Poppins, sans-serif',
  fontSize: '15px'
});

export const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;

      .MuiTabs-indicator {
        box-shadow: none;
      }
    }
`
);

export default GlobalStyles;
