import { BookingsAction, BookingsActionTypes } from './bookingsActionTypes';

export const bookings = (data: any): BookingsAction => ({
  type: BookingsActionTypes.BOOKINGS,
  payload: data
});

export const bookingsSuccess = (data: any): BookingsAction => ({
  type: BookingsActionTypes.BOOKINGS_SUCCESS,
  payload: data
});

export const bookingsError = (error: string): BookingsAction => ({
  type: BookingsActionTypes.BOOKINGS_ERROR,
  payload: error
});
