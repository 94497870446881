export enum BookingsActionTypes {
  BOOKINGS = 'bookings/BOOKINGS',
  BOOKINGS_SUCCESS = 'bookings/BOOKINGS_SUCCESS',
  BOOKINGS_ERROR = 'bookings/BOOKINGS_ERROR'
}

export interface BookingsAction {
  type: BookingsActionTypes;
  payload?: any;
}
