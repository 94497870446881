import React, { createContext, useContext, useEffect, useState } from 'react';
import { Message, VideoMessage } from '../types';

class WebSocketService {
  private socket: WebSocket | null = null;
  private onOpenCallback: ((event: Event) => void) | null = null;
  onCloseCallback: () => void;

  constructor() {
    this.setupBeforeUnloadListener();
  }

  private setupBeforeUnloadListener() {
    window.addEventListener('beforeunload', () => {
      this.disconnect();
    });
  }

  connect(url: string) {
    this.disconnect(); // Ensure any existing connection is closed
    this.socket = new WebSocket(url);

    this.socket.onopen = (event) => {
      if (this.onOpenCallback) {
        this.onOpenCallback(event);
      }
    };

    this.socket.onclose = () => {
      console.log('Disconnected from WebSocket server');
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  }

  disconnect() {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
  }

  sendMessage(message: Message, username: string, selectedReceiver: string) {
    console.log('Sending message:', message, 'to:', selectedReceiver);
    if (this.socket?.readyState !== this.socket?.OPEN) {
      this.connect(localStorage.getItem('webSocketUrl')!);
    }
    this.socket?.send(
      JSON.stringify({
        action: 'sendMessage',
        connectionId: username,
        message_type: 'individual',
        receiver: selectedReceiver,
        reactions: [],
        owner: username,
        createdAt: new Date().toISOString(),
        message: JSON.stringify(message)
      })
    );
  }

  sendVideoMessage(videoMessage: VideoMessage) {
    if (this.socket?.readyState !== this.socket?.OPEN) {
      this.connect(localStorage.getItem('webSocketUrl')!);
    }
    this.socket?.send(JSON.stringify(videoMessage));
    console.log('Sending video message');
  }

  onOpen(callback: (event: Event) => void) {
    this.onOpenCallback = callback;
  }

  onClose(callback: () => void) {
    this.onCloseCallback = callback;
  }

  onMessage(callback: (message: string) => void) {
    if (this.socket) {
      this.socket.onmessage = (event) => {
        callback(JSON.parse(event.data));
      };
    }
  }
}

// Create a context
const WebSocketContext = createContext<WebSocketService | null>(null);

// Create a provider component
export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [webSocketService] = useState(new WebSocketService());

  useEffect(() => {
    // Cleanup on unmount
    return () => {
      webSocketService.disconnect();
    };
  }, [webSocketService]);

  return <WebSocketContext.Provider value={webSocketService}>{children}</WebSocketContext.Provider>;
};

// Custom hook to use the WebSocket context
export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};

export default WebSocketContext;
